<template>
  <el-form
    :model="addForm"
    :rules="rules"
    ref="addForm"
    label-width="102px"
    label-position="left"
    class="demo-form dialog-form"
  >
    <el-form-item label="流程名称：" prop="name">
      <el-input v-model.trim="addForm.name" placeholder="请输入流程名称"></el-input>
    </el-form-item>
    <el-form-item label="追溯产品：" prop="productId">
      <el-select v-model="addForm.productId" placeholder="请选择追溯产品">
        <el-option
          v-for="product in productList"
          :label="product.name"
          :value="product.id"
          :key="product.id"
          :disabled="product.status!==0"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="字段模板：" prop="templateId">
      <el-select v-model="addForm.templateId" @change="onChangeTemplate" placeholder="请选择字段模板">
        <el-option
          v-for="template in templateList"
          :label="template.name"
          :value="template.id"
          :key="template.id"
          :disabled="template.status!==0"
        ></el-option>
      </el-select>
    </el-form-item>
    <div
      class="templateJsonWrapper"
      v-if="templateDataJSON.length>0"
      v-show="templateDataJSON.length>0"
    >
      <el-form-item
        v-for="(tpdata,index) in templateDataJSON"
        :label="tpdata.label+'：'"
        :key="index"
        :rules="{required: true, message: '不能为空', trigger: 'blur'}"
      >
        <el-date-picker
          v-if="tpdata.type==='date'"
          v-model="addForm.dynamicItem[tpdata.fieldName]"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期时间"
          default-time="12:00:00"
        ></el-date-picker>
        <addUpload
          v-else-if="tpdata.type==='imge_upload'||tpdata.type==='vido_upload'"
          :type="tpdata.type"
          :filedUrl="tpdata.value"
          :fieldName="tpdata.fieldName"
          @handleSuccess="handleSuccess"
          @handleRemove="handleRemove"
          ref="upload"
        />
        <el-input
          v-else
          v-model="addForm.dynamicItem[tpdata.fieldName]"
          :placeholder="'请输入'+tpdata.label+'名称'"
        ></el-input>
      </el-form-item>
    </div>
    <el-form-item label="流程是否完结：" prop="tailNode">
      <el-switch v-model="addForm.tailNode" :active-value="1" :inactive-value="0"></el-switch>
    </el-form-item>
  </el-form>
</template>
<script>
import { checkRoleName, checkRoleCode } from "@/common/utils/index";
import {
  insertProcess,
  updateProcess,
  getProcessById,
  getBusinessPage,
  getProdsPage,
  getFieldsPage,
  getFieldsById,
  UPLOAD_URL,
  HEADERS,
  IMG_PREFIX,
} from "@/api/lib/api.js";
import addUpload from "./addUpload";
import { checkName } from "@/common/utils/index.js";

export default {
  components: { addUpload },
  props: {
    processId: {
      type: [Number, String],
    },
    parentId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      addForm: {
        templates: [
          {
            name: "",
            type: "",
          },
        ],
        businessId: "",
        description: "",
        dynamicItem: {},
      },
      productList: [],
      templateList: [],
      templateDataJSON: [],
      templateData: "",
      rules: {
        name: [
          {
            required: true,
            message: "请输入流程名称",
            trigger: "blur",
          },
          { validator: checkName, trigger: "blur" },
        ],
        productId: [
          { required: true, message: "请选择追溯产品", trigger: "blur" },
        ],
        templateId: [
          { required: true, message: "请选择字段模板", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 新增/保存用户
    onSave() {
      this.$refs.addForm.validate((valid) => {
        let tpData = [];
        //console.log(this.addForm.dynamicItem)
        tpData.push(this.addForm.dynamicItem);
        const tpDatastr = JSON.stringify(tpData);
        if (valid) {
          let data = {
            name: this.addForm.name,
            parentId: this.parentId,
            productId: this.addForm.productId,
            tailNode: this.addForm.tailNode,
            templateData: tpDatastr,
            templateId: this.addForm.templateId,
          };
          if (this.processId) {
            data.id = this.addForm.id;
            updateProcess(data).then((res) => {
              if (res.code === 0) {
                this.$message.success("修改成功");
                let mdata = res.data;
                if (mdata && mdata.traceQr && mdata.traceQr !== null) {
                  this.$emit("onDialogCodeVisible", mdata.traceQr);
                }
              } else {
                this.$message.error(res.message);
              }
              this.$emit("onDialogClose", res.code === 0);
            });
          } else {
            insertProcess(data).then((res) => {
              if (res.code === 0) {
                this.$message.success("新增成功");
                let mdata = res.data;
                if (mdata && mdata.traceQr && mdata.traceQr !== null) {
                  this.$emit("onDialogCodeVisible", mdata.traceQr);
                }
              } else {
                this.$message.error(res.message);
              }
              this.$emit("onDialogClose", res.code === 0);
            });
          }
        }
      });
    },
    addTemplate() {
      this.addForm.templates.push({
        name: "",
        type: "",
      });
    },
    removeTemplate(item) {
      var index = this.addForm.templates.indexOf(item);
      if (index !== -1) {
        this.addForm.templates.splice(index, 1);
      }
    },
    //获取产品列表
    getProdsPage() {
      getProdsPage().then((res) => {
        if (res.code === 0) {
          this.productList = res.data.records;
        }
      });
    },
    //获取模板列表
    getFieldsPage() {
      getFieldsPage().then((res) => {
        if (res.code === 0) {
          this.templateList = res.data.records;
        }
      });
    },
    //选中模板获取模板json
    onChangeTemplate(value) {
      this.$refs.addForm.clearValidate();
      if (this.$refs.upload) {
        this.$refs.upload.forEach((item) => {
          item.clearValue();
        });
      }

      if (value) {
        getFieldsById(value).then((res) => {
          if (res.code === 0) {
            this.templateDataJSON = JSON.parse(res.data.content);
          }
        });
      }
    },
    //弹出溯源流程二维码
    alertTraceCode(id) {
      getProcessById(id).then((res) => {
        if (res.code === 0) {
          let data = res.data;
          if (data && data.traceQr && data.traceQr !== null) {
            this.$emit("onDialogClose", data.traceQr);
          }
        }
      });
    },
    handleSuccess(name, url) {
      this.addForm.dynamicItem[name] = url;
    },
    handleRemove(name) {
      this.addForm.dynamicItem[name] = "";
    },
  },

  created() {
    this.getProdsPage();
    this.getFieldsPage();
    if (this.processId) {
      getProcessById(this.processId).then((res) => {
        if (res.code === 0) {
          this.addForm = { ...res.data };
          if (res.data.traceCode !== null) {
            this.addForm.tailNode = 1;
          } else {
            this.addForm.tailNode = 0;
          }
          if (res.data.fieldList && res.data.fieldList.length > 0) {
            let tempDyn = {};
            res.data.fieldList.forEach((element) => {
              tempDyn[element.fieldName] = element.value;
              // if (element.type === "imge_upload" && element.value) {
              //   this.imageUrl = IMG_PREFIX + element.value;
              // }
              // if (element.type === "vido_upload") {
              //   this.videoForm.showVideoPath = IMG_PREFIX + element.value;
              // }
            });
            //console.log(tempDyn);
            this.addForm.dynamicItem = tempDyn;
            this.templateDataJSON = res.data.fieldList;
          }
        }
      });
    }
  },
  mounted() {},
};
</script>
