<template>
  <div>
    <el-upload
      v-if="type==='imge_upload'"
      :action="fileUploadUrl"
      list-type="picture-card"
      :show-file-list="false"
      accept="image/png, image/gif, image/jpg, image/jpeg"
      :headers="fileHeaders"
      :before-remove="handleBeforeRemove"
      :on-success="handleAvatarSuccess"
      ref="uploadImg"
    >
      <img v-if="imageUrl" :src="imageUrl" class="imgs" />
      <i v-else class="el-icon-plus"></i>
    </el-upload>
    <el-upload
      v-else
      :action="fileUploadUrl"
      :headers="fileHeaders"
      list-type="picture-card"
      :on-progress="uploadVideoProcess"
      :on-success="handleVideoSuccess"
      :before-upload="beforeUploadVideo"
      :show-file-list="false"
      ref="uploadVideo"
    >
      <video
        v-if="showVideoPath && !videoFlag"
        :src="showVideoPath"
        class="video-avatar"
        controls="controls"
      >您的浏览器不支持视频播放</video>
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      <el-progress
        v-if="videoFlag == true"
        type="circle"
        :percentage="videoUploadPercent"
        style="margin-top:7px;"
      ></el-progress>
    </el-upload>
  </div>
</template>
<script>
import { checkRoleName, checkRoleCode } from "@/common/utils/index";
import {
  insertProcess,
  updateProcess,
  getProcessById,
  getBusinessPage,
  getProdsPage,
  getFieldsPage,
  getFieldsById,
  UPLOAD_URL,
  HEADERS,
  IMG_PREFIX,
} from "@/api/lib/api.js";

export default {
  components: {},
  props: {
    type: {
      type: [Number, String],
    },
    fieldName: {
      type: [Number, String],
    },
    filedUrl: {
      type: [Number, String],
    },
  },
  data() {
    return {
      addForm: {
        templates: [
          {
            name: "",
            type: "",
          },
        ],
        businessId: "",
        description: "",
        dynamicItem: {},
      },
      fileHeaders: "",
      fileUploadUrl: "",
      videoFlag: false,
      imageUrl: null,
      //是否显示进度条
      videoUploadPercent: "",
      //进度条的进度，
      isShowUploadVideo: false,
      //显示上传按钮
      showVideoPath: "",
    };
  },
  methods: {
    // 移除照片确认
    handleBeforeRemove(file, fileList) {},
    // 成功添加照片
    handleAvatarSuccess(res, file) {
      if (res.code === 0) {
        this.imageUrl = IMG_PREFIX + res.data.url;
        this.$emit("handleSuccess", this.fieldName, res.data.url);
      }
    },
    //上传前回调
    beforeUploadVideo(file) {
      var fileSize = file.size / 1024 / 1024 < 50;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
          "video/mov",
        ].indexOf(file.type) == -1
      ) {
        this.$message.error("请上传正确的视频格式");
        return false;
      }
      if (!fileSize) {
        this.$message.error("视频大小不能超过50MB");
        return false;
      }
      this.isShowUploadVideo = false;
    },
    //进度条
    uploadVideoProcess(event, file, fileList) {
      this.videoFlag = true;
      this.videoUploadPercent = file.percentage.toFixed(0) * 1;
    },
    //上传成功回调
    handleVideoSuccess(res, file) {
      this.isShowUploadVideo = true;
      this.videoFlag = false;
      this.videoUploadPercent = 0;

      //后台上传地址
      if (res.code === 0) {
        this.showVideoPath = IMG_PREFIX + res.data.url;
        this.$emit("handleSuccess", this.fieldName, res.data.url);
      } else {
        this.$message.error(res.Message);
      }
    },
    clearValue() {
      this.imageUrl = null;
      this.showVideoPath = null;

    },
  },
  created() {
    this.fileHeaders = HEADERS;
    this.fileUploadUrl = UPLOAD_URL;
    this.imageUrl = this.filedUrl ? `${IMG_PREFIX}${this.filedUrl}` : null;
    this.showVideoPath = this.filedUrl ? `${IMG_PREFIX}${this.filedUrl}` : null;
  },
  mounted() {},
};
</script>
<style lang="scss" >
.imgs {
  height: 100px;
  width: 100px;
}
.video-avatar {
  height: 150px;
}
</style>