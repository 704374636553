 <template>
  <div class="process-management" ref="processManagement">
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <!-- <el-form-item label="所属行业：">
          <el-input v-model.trim="form.businessId" placeholder="请输入所属行业"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="form.status" clearable placeholder="请选择状态">
            <el-option label="正常" :value="0"></el-option>
            <el-option label="锁定" :value="1"></el-option>
          </el-select>
        </el-form-item>-->
        <el-form-item>
          <!-- <el-button type="primary" size="small" @click="onSearch">查询</el-button> -->
          <el-button type="primary" size="small" @click="onaddProcess">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      row-key="id"
      style="width: 100%"
      stripe
      :height="tableHeight"
      lazy
      :load="load"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      >
      <el-table-column prop="name" label="流程名称" width="170" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="productId" label="产品ID" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="status" label="是否锁定流程状态">
        <template slot-scope="scope">
          <el-switch
            @change="changeStatus($event,scope.row)"
            v-model="scope.row.status"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="170" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="createBy" label="创建人"></el-table-column>

      <el-table-column label="操作" width="280">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit-outline"
            type="text"
            size="small"
            sort="primary"
            @click="onEditProcess(scope.row)"
          >编辑</el-button>
          <el-button
            icon="el-icon-plus"
            type="text"
            size="small"
            sort="authorize"
            @click="onaddChildProcess(scope.row)"
          >新增子流程</el-button>
          <el-button
            icon="el-icon-delete"
            type="text"
            size="small"
            sort="danger"
            @click="onDeleteProcess(scope.row)"
          >删除</el-button>
          <!-- <el-button
						icon="el-icon-delete"
						type="text"
						size="small"
						@click="onDialogCodeVisible(scope.row.traceQr)"
                        v-show="scope.row.traceQr!==null"
          >查看二维码</el-button>-->
          <img
            class="codeImg"
            v-show="scope.row.traceQr!==null"
            @click="onDialogCodeVisible(scope.row.traceQr)"
            src="/images/code.png"
            alt
            srcset
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <el-dialog :title="title" v-if="dialogVisible" :visible.sync="dialogVisible" width="40%">
      <div>
        <div class="body"></div>
        <addProcess
          ref="addProcess"
          :processId="processId"
          :parentId="parentId"
          @onDialogClose="onDialogClose"
          @onDialogCodeVisible="onDialogCodeVisible"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="onSave"
        >{{ title.indexOf("新增") !== -1 ? "新增" : "保存"}}</el-button>
      </span>
    </el-dialog>
    <el-dialog title="追溯二维码" v-if="dialogCodeVisible" :visible.sync="dialogCodeVisible" width="30%">
      <div>
        <div class="codebody">
          <h3>产品追溯流程已完结，扫描下方二维码查看详情</h3>
          <img :src="codeImg" alt="查询追溯流程二维码" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogCodeVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

  <script>
import addProcess from "./components/addProcess";
import {
  getProcessPage,
  updateProcessStatus,
  deleteProcessById,
  getProcessChild,
} from "@/api/lib/api.js";
export default {
  name: "processManagement",
  components: {
    addProcess,
  },
  data () {
    return {
      tableHeight: 0,
      total: 0,
      tableData: [],
      form: {
        businessId: "",
        status: null,
        current: 1,
        size: 10,
      },
      multipleSelection: [],
      title: "",
      processId: null,
      dialogVisible: false,
      dialogCodeVisible: false,
      parentId: 0,
      codeImg: "",
    };
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      let wholeHeight = this.$refs.processManagement.clientHeight;
      let formHeight = this.$refs.form.clientHeight;
      let paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight - 16 - formHeight - paginationHeight - 10 - 16 - 35;
    },
    // 点击查询
    onSearch () {
      this.form.current = 1;
      this.getProcessListByField();
    },
    //获取数据
    getProcessListByField () {
      getProcessPage(this.form).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data.records;
          this.total = res.data.total;
          if (!res.data.records.length && this.form.current !== 1) {
            //如果当前页的数据为0则设置页面为前一页 但是确保当前页不为1
            self.form.current -= 1;
            self.getProcessListByField();
          }
        } else {
          this.tableData = [];
          this.total = 0;
          this.$message.error(res.message);
        }
      });
    },
    //新增
    onaddProcess () {
      this.title = "新增追溯流程";
      this.processId = "";
      this.dialogVisible = true;
      this.parentId = 0;
    },
    onaddChildProcess (row) {
      this.title = "新增子流程";
      this.processId = "";
      this.dialogVisible = true;
      this.parentId = row.id;
    },
    onEditProcess (row) {
      this.title = "编辑追溯流程";
      this.processId = row.id;
      this.dialogVisible = true;
    },
    // 修改追溯流程状态
    changeStatus (e, row) {
      let message = e === 1 ? "锁定" : "启用";
      updateProcessStatus({ id: row.id, status: String(e) }).then(
        (res) => {
          if (res.code === 0) {
            this.$message.success(`${message}成功`);
          } else {
            this.$message.error(`${message}失败`);
            row.status = e === 1 ? 0 : 1;
          }
        }
      );
    },
    //删除追溯流程
    onDeleteProcess (row) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [
          h("span", null, "确定删除追溯流程 "),
          h("i", { style: "color: #059370" }, row.name),
          h("span", null, " ？"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteProcessById(row.id).then((res) => {
            if (res.code === 0) {
              this.$message.success("删除成功");
              this.getProcessListByField();
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消删除",
          });
        });
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.current = page;
      this.getProcessListByField();
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.size = size;
      this.getProcessListByField();
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    // 弹窗关闭时的回调
    onDialogClose (flag) {
      this.dialogVisible = false;
      if (flag) {
        this.getProcessListByField();
      }
    },
    onDialogCodeVisible (imgsrc) {
      console.log(imgsrc);
      this.dialogCodeVisible = true;
      this.codeImg = "" + imgsrc;
    },
    onSave () {
      this.$refs.addProcess.onSave();
    },
    load (tree, treeNode, resolve) {
      getProcessChild(tree.id).then((res) => {
        console.log(res);
        if (res.code === 0) {
          resolve(res.data);
        } else {
          this.$message.error("获取子流程失败");
        }
      });
    },
  },
  filters: {
    filterContent: function (arr) {
      let content = "";
      arr.forEach((element) => {
        content.push(`${element.label}：${element.fieldName}`);
      });
      return content.join("|");
    },
  },
  created () {
    this.onSearch();
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>
<style lang="scss" scoped>
.process-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  .avatarImg {
    vertical-align: middle;
    border-radius: 50%;
  }
}

.theme-light {
  .process-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
.codebody {
  text-align: center;
}
.codeImg {
  margin-left: 1rem;
  cursor: pointer;
  vertical-align: middle;
}
</style>